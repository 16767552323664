const entryCards = [
  {
    title: 'livingWill',
    icon: '/assets/icons/ic-living-will.svg',
    url: '/dashboard/beratung/user-erstellen/patientenverfuegung',
  },
  {
    title: 'powerOfAttorney',
    icon: '/assets/icons/ic-power-of-attorney.svg',
    url: '/dashboard/beratung/user-erstellen/vorsorgeauftrag',
  },
  {
    title: 'lastWill',
    icon: '/assets/icons/ic-last-will.svg',
    url: '/dashboard/beratung/user-erstellen/testament',
  },
  {
    id: 2,
    title: 'ageAppropriate',
    icon: '/assets/icons/ic-living-will.svg',
    url: '/dashboard/beratung',
  },
  {
    id: 1,
    title: 'ahvCalculator',
    icon: '/assets/icons/ic-power-of-attorney.svg',
    url: '/dashboard/beratung',
  },
  {
    id: 3,
    title: 'lifeExpectancy',
    icon: '/assets/icons/ic-last-will.svg',
    url: '/dashboard/life-expectancy',
  },
];

export default entryCards;
