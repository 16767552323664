import { Container, Box, Stack, Typography, Grid } from '@mui/material';
import React, { useState } from 'react';
import useLocales from '#/hooks/useLocales';
import entryCards from '#/components/pages/AdminConsulting/entryData';
import EntryCard from '#/components/pages/AdminConsulting/cards/entry-card';
import { useGetWidgetList } from '#/api/partnerQueries';
import UnlockWidget from '#/components/shared/UnlockWidget';

export default function ConsultingMain() {
  const [open, setOpen] = useState(false);

  const { translate } = useLocales();

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const { data: widgets } = useGetWidgetList();

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Stack direction="column" spacing={2} width={1}>
          <Stack
            spacing={3}
            sx={{
              textAlign: 'center',
              mx: {
                xs: 0,
                md: 10,
              },
            }}
          >
            <Typography variant="h2">
              {String(translate('global.consulting.title'))}
            </Typography>
            <Typography variant="body1">
              {String(translate('global.consulting.description'))}
            </Typography>
          </Stack>
        </Stack>
        <Grid
          container
          spacing={3}
          sx={{
            mt: {
              xs: 3,
              md: 13,
            },
          }}
        >
          {entryCards.slice(0, 3).map((card) => (
            <Grid item xs={12} md={4} key={card.title}>
              <EntryCard {...card} requested />
            </Grid>
          ))}
          {entryCards.slice(3).map((card) => (
            <Grid item xs={12} md={4} key={card.title}>
              <EntryCard
                title={card.title}
                icon={card.icon}
                url={card.url}
                requested={
                  widgets?.widgets.find((widget) => widget.id === card.id)
                    ?.requested
                }
                handleOpenModal={handleOpenModal}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <UnlockWidget open={open} onClose={handleCloseModal} />
    </Container>
  );
}
